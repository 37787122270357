import { useCallback, useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import DataTable, { TableColumn } from "react-data-table-component";
import i18next from "i18next";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Tooltip } from "@mui/material";
import { AgentLogStatus, StepLogModel, UpdateStatusAction, StepLogActionByEventPostModel, StepLogActionByStepPostModel, AgentLogStatusLabel, ScreenshotModalData, OperationSystemSessionState, StepType } from "app/dashboard/model/dashboard.model";
import { DayOfWeek, EventRecurring, EventRecurringType } from "app/dashboard/model/recurring.model";
import { getStatusColor, getTimeLineBadge } from "app/dashboard/utils/dashboardUtils";
import LoadingSpinner from "common/partials/LoadingSpinner";
import { itLabTableStyle } from "common/helpers/table/table-style";
import { TokenHelper } from "common/helpers/auth/token-helper";
import { RolesDictionary } from "common/helpers/dictionaries/roles-dictionary";
import { formatRelativeTime, formatLocalizedDateTime } from "common/helpers/dateUtils";
import { DashboardService as Service } from "../../../services/dashboard.service";
import en from "../../../i18n/en-us.json";
import pt from "../../../i18n/pt-br.json";
import es from "../../../i18n/es-es.json";
import ExecutionLogAvailableMachinesModal from "./components/ExecutionLogAvailableMachinesModal";
import DashboardContext from "app/dashboard/context/dashboard.context";
import enumValuesExist from "app/shared/utility/functions/enumValuesExist";
import moment from "moment";
import ScreenshotModal from "../ScreenshotModal";
import FeatureFlag from "common/partials/FeatureFlag";
import FeatureFlagEnum from "common/enums/FeatureFlagEnum";

export function ExecutionLog() {
	i18next.addResourceBundle("us", "translation", en);
	i18next.addResourceBundle("br", "translation", pt);
	i18next.addResourceBundle("es", "translation", es);

	const { t } = useTranslation();
	const { hubConnection, filteredGroupedRobots, stepLogIsLoading, UpdateSingleItemStatusOnList, UpdateMachineStatusOnList, filtered } = useContext(DashboardContext);
	const [isLoading, setIsLoading] = useState<boolean>(false);

	const [stepLogMessage, setStepLogMessage] = useState<string>("");
	const [progressStatusMessage, setProgressStatusMessage] = useState<UpdateStatusAction>();
	const [machineConnectionMessage, setMachineConnectionMessage] = useState<any>(undefined);
	const [updateSessionUpdate, setUpdateSessionUpdate] = useState<any>(undefined);
	const [openModal, setOpenModal] = useState<boolean>(false);
	const [stepExecutionData, setStepExecutionData] = useState<StepLogModel | null>(null);
	const [expandedDepartments, setExpandedDepartments] = useState<string[]>([]);
	const [showScreenshotModal, setShowScreenshotModal] = useState<boolean>(false);
	const [screenshotModalData, setScreenshotModalData] = useState<ScreenshotModalData>();

	const tokenHelper = new TokenHelper();

	const handleMessage = (message: any) => {
		if (message.messageCode === "start") {
			toast.success(`${t("stepLog.executionStarted", { stepName: message.message })}`);
		}
	}

	useEffect(() => {
		const receiveMessages = async () => {
			if (hubConnection) {
				hubConnection.on("GetStepLog", message => setStepLogMessage(message));
				hubConnection.on("UpdateScheduleStatus", message => setProgressStatusMessage(message));
				hubConnection.on("UpdateConnection", message => setMachineConnectionMessage(message));
				hubConnection.on("UpdateSession", message => setUpdateSessionUpdate(message));
				hubConnection.on("GetMessage", handleMessage);
			}
		}
		receiveMessages();

		return (() => {
			if (hubConnection) {
				hubConnection.off("GetStepLog");
				hubConnection.off("UpdateScheduleStatus");
				hubConnection.off("UpdateConnection");
				hubConnection.off("UpdateSession");
				hubConnection.off("GetMessage");
			}
		})
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [hubConnection, handleMessage]);

	const verifyStepStatus = (status?: AgentLogStatus) => {
		const enumExists = enumValuesExist(AgentLogStatus, status)
		if (!status || !enumExists) return t("stepLog.table.status.noStatus");
		return t(`stepLog.table.status.${AgentLogStatusLabel[status]}`);
	};

	const buildBadges = (items: string | null): JSX.Element[] => {
		let html: JSX.Element[] = [];

		try {
			if (items) {
				const jsonObject = JSON.parse(items);

				Object.keys(jsonObject).forEach((key) => {
					const normalizedKey = key.toLowerCase();
					if (normalizedKey === 'endprocess' || normalizedKey === 'restartprocess') {
						delete jsonObject[key];
					}
				});

				html = Object.keys(jsonObject).map((key, index) => (
					<span className="badge badge-light-primary fs-8 fw-bold my-2 me-3" key={index}>{key}: {jsonObject[key]}</span>
				));
			}
		} catch (error) {
			html.push(<span className="badge badge-light fs-8 fw-bold my-2 me-3">?: ?</span>);
		}

		return html;
	};

	const startUnscheduledStep = async (body: StepLogActionByStepPostModel) => {
		setIsLoading(true);

		const response = await Service.startUnscheduleStep(body);
		if (response && !response.hasErrors) {
			toast.success(`${t("crud.start.successMessage")}`);
		} else {
			toast.error(`${t("crud.start.errorMessage")}`);
		}

		setIsLoading(false);
	};

	const handleStopButtonClicked = async (body: StepLogActionByEventPostModel) => {
		setIsLoading(true);
		const response = await Service.stopStep(body);
		if (response && !response.hasErrors) {
			toast.success(`${t("crud.stop.successMessage")}`);
		} else {
			toast.error(`${t("crud.stop.errorMessage")}`);
		}
		setIsLoading(false);
	};

	const refreshStep = async (body: StepLogActionByEventPostModel) => {
		setIsLoading(true);
		const response = await Service.refreshStep(body);
		if (response && !response.hasErrors) {
			toast.success(`${t("crud.refresh.successMessage")}`);
		} else {
			toast.error(`${t("crud.refresh.errorMessage")}`);
		}
		setIsLoading(false);
	};

	const handleOpenModal = (row: StepLogModel) => {
		setStepExecutionData(row);
		setOpenModal(true);
	}

	const handleCloseModal = () => {
		setStepExecutionData(null);
		setOpenModal(false);
	}

	const handleStartStep = useCallback(async (row: StepLogModel) => {
		if (row.isScheduled && row.eventId) {
			setIsLoading(true);
			row.status = AgentLogStatus.Triggered;

			const body: StepLogActionByEventPostModel = {
				eventId: row.eventId,
				stepId: row.stepId
			}

			const response = await Service.startScheduledStep(body);
			if (response && !response.hasErrors) {
				UpdateSingleItemStatusOnList(AgentLogStatus.Triggered, body.eventId!);
				toast.success(`${t("crud.start.successMessage")}`);
			} else {
				toast.error(`${t("crud.start.errorMessage")}`);
			}

			setIsLoading(false);
		} else {
			handleOpenModal(row);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		// getStepLog();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [stepLogMessage]);

	useEffect(() => {
		if (progressStatusMessage) {
			filteredGroupedRobots.forEach(robot => {
				const changedEvent = robot.events.find((event: StepLogModel) => event.eventId === progressStatusMessage.eventId);
				if (changedEvent) {
					changedEvent.status = progressStatusMessage.status;
					if (progressStatusMessage.realTimeLogViewModel) {
						changedEvent.realTimeLogViewModel = progressStatusMessage.realTimeLogViewModel;
						changedEvent.percentage = progressStatusMessage.realTimeLogViewModel.progress;
					}
				}
			});
			setProgressStatusMessage(undefined);
		}
	}, [progressStatusMessage, filteredGroupedRobots]);

	useEffect(() => {
		if (machineConnectionMessage) {
			const connectionData = JSON.parse(machineConnectionMessage.message);
			if (machineConnectionMessage.messageCode === "update-connection") {
				filteredGroupedRobots.forEach(robot => {
					robot.events
						.filter((event: StepLogModel) => event.machineName === connectionData.machineName)
						.forEach(event => event.machineIsUp = connectionData.connected);
				});
			}
			setMachineConnectionMessage(undefined);
		}
	}, [machineConnectionMessage, filteredGroupedRobots])

	useEffect(() => {
		if (updateSessionUpdate) {
			if (updateSessionUpdate.messageCode === "unlocked") {
				toast.info(`${t("stepLog.machine.unlocked", { machineName: updateSessionUpdate.message })}`);
				UpdateMachineStatusOnList(updateSessionUpdate.message, OperationSystemSessionState.Unlock);
				filteredGroupedRobots.forEach(robot => {
					robot.events
						.filter((event: StepLogModel) => event.machineName === updateSessionUpdate.message)
						.forEach(event => event.operationSystemSessionState = OperationSystemSessionState.Unlock);
				});
			} else if (updateSessionUpdate.messageCode === "locked") {
				toast.warning(`${t("stepLog.machine.locked", { machineName: updateSessionUpdate.message })}`);
				UpdateMachineStatusOnList(updateSessionUpdate.message, OperationSystemSessionState.Lock);
				filteredGroupedRobots.forEach(robot => {
					robot.events
						.filter((event: StepLogModel) => event.machineName === updateSessionUpdate.message)
						.forEach(event => event.operationSystemSessionState = OperationSystemSessionState.Lock);
				});
			}
			setUpdateSessionUpdate(undefined);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [UpdateMachineStatusOnList, updateSessionUpdate]);

	const getRecurringTypeIcon = (recurringType?: EventRecurringType) => {
		switch (recurringType) {
			case EventRecurringType.Daily:
				return { icon: <i className="fa-solid fa-d text-primary" />, description: t('stepLog.table.recurring.type.daily') };
			case EventRecurringType.Weekly:
				return { icon: <i className="fa-solid fa-s text-primary" />, description: t('stepLog.table.recurring.type.weekly') };
			case EventRecurringType.Monthly:
				return { icon: <i className="fa-solid fa-m text-primary" />, description: t('stepLog.table.recurring.type.montly') };
			case EventRecurringType.Single:
			default:
				return { icon: <i className="fa-solid fa-u text-primary" />, description: t('stepLog.table.recurring.type.single') };
		}
	};

	const getRecurringTimeIcon = (log: StepLogModel) => {
		let startTime = '-';
		let endTime = null;
		const fakeDate = moment().format('YYYY-MM-DD');
		// date fake created to fix issue to get formated time from time

		if (log.eventStartTime) {
			startTime = formatLocalizedDateTime(`${fakeDate} ${log.eventStartTime}`, 'HH:mm') ?? '-';
		} else if (log.eventRecurring && log.eventRecurring.startTime) {
			startTime = formatLocalizedDateTime(`${fakeDate} ${log.eventRecurring.startTime}`, 'HH:mm') ?? '-';
		}

		if (log.eventRecurring && log.eventRecurring.endTime) {
			endTime = formatLocalizedDateTime(`${fakeDate} ${log.eventRecurring.endTime}`, 'HH:mm');
		}

		const description = endTime
			? t('stepLog.table.recurring.time.start', { startTime }) + " | " + t('stepLog.table.recurring.time.end', { endTime })
			: t('stepLog.table.recurring.time.start', { startTime });

		return {
			icon: endTime ? <i className="fa-solid fa-clock-rotate-left text-primary" /> : <i className="fa-regular fa-clock text-primary" />,
			description: description
		};
	};

	const getHolidayIcon = (holiday?: boolean) => {
		const color = holiday ? 'primary' : 'secondary';
		const description = holiday ? t('stepLog.table.recurring.holiday.true') : t('stepLog.table.recurring.holiday.false');

		return {
			icon: <i className={`fa-solid fa-umbrella-beach text-${color}`} />,
			description: description,
			color: color
		};
	};

	const getWeekendIcon = (weekend?: boolean) => {
		const color = weekend ? 'primary' : 'secondary';
		const description = weekend ? t('stepLog.table.recurring.weekend.true') : t('stepLog.table.recurring.weekend.false');

		return {
			icon: <i className={`fa-solid fa-w text-${color}`} />,
			description: description,
			color: color
		};
	};

	const getDayOfWeekDescription = (daysOfWeek?: DayOfWeek[]) => {
		if (!daysOfWeek || daysOfWeek.length === 0) {
			return '-';
		}

		const dayOfWeekMap: { [key: number]: string } = {
			[DayOfWeek.Sunday]: t('daysOfWeek.sunday'),
			[DayOfWeek.Monday]: t('daysOfWeek.monday'),
			[DayOfWeek.Tuesday]: t('daysOfWeek.tuesday'),
			[DayOfWeek.Wednesday]: t('daysOfWeek.wednesday'),
			[DayOfWeek.Thursday]: t('daysOfWeek.thursday'),
			[DayOfWeek.Friday]: t('daysOfWeek.friday'),
			[DayOfWeek.Saturday]: t('daysOfWeek.saturday')
		};

		const daysList = daysOfWeek.map(day => dayOfWeekMap[day]);
		let description = '';

		if (daysList.length === 1) {
			description = t('stepLog.table.recurring.daysOfWeek.day_one', { dayOfWeek: daysList[0] });
		} else if (daysList.length === 2) {
			description = t('stepLog.table.recurring.daysOfWeek.day_multiple', { daysOfWeek: daysList[0], othersDaysOfWeek: daysList[1] });
		} else {
			description = t('stepLog.table.recurring.daysOfWeek.day_multiple', { daysOfWeek: daysList.slice(0, -1).join(', '), othersDaysOfWeek: daysList.slice(-1) });
		}

		return description;
	};

	const getDayOfMonthDescription = (daysOfMonth?: number[]) => {
		if (!daysOfMonth || daysOfMonth.length === 0) {
			return '-';
		}

		const daysList = daysOfMonth.sort((a, b) => a - b);
		let description = '';

		if (daysList.length === 1) {
			description = t('stepLog.table.recurring.daysOfMonth.day_one', { dayOfMonth: daysList[0] });
		} else if (daysList.length === 2) {
			description = t('stepLog.table.recurring.daysOfMonth.day_multiple', { daysOfMonth: daysList[0], othersDaysOfMonth: daysList[1] });
		} else {
			description = t('stepLog.table.recurring.daysOfMonth.day_multiple', { daysOfMonth: daysList.slice(0, -1).join(', '), othersDaysOfMonth: daysList.slice(-1) });
		}

		return description;
	};

	const getMoreOptionsIcon = (recurring?: EventRecurring) => {
		let descriptions = [];

		if (recurring?.separationTime) {
			descriptions.push(t('stepLog.table.recurring.moreOptions.separationTime', { separationTime: recurring.separationTime }));
		}

		return {
			icon: <i className="fa-solid fa-ellipsis text-primary" />,
			descriptions: descriptions
		};
	};

	const renderRecurringIcons = (log: StepLogModel, recurring?: EventRecurring) => {
		const typeIcon = getRecurringTypeIcon(recurring?.recurringType);
		const timeIcon = getRecurringTimeIcon(log);
		const weekendIcon = getWeekendIcon(recurring?.weekend);
		const holidayIcon = getHolidayIcon(recurring?.holiday);
		const moreOptionsIcon = getMoreOptionsIcon(recurring);

		return (
			<div style={{ display: 'grid', gridTemplateColumns: 'repeat(3, auto)', gap: '5px', alignItems: 'center' }}>
				<Tooltip title={typeIcon.description} arrow>
					<span className="badge badge-light-primary">{typeIcon.icon}</span>
				</Tooltip>
				{recurring?.recurringType === EventRecurringType.Weekly && (
					<Tooltip title={getDayOfWeekDescription(recurring.daysOfWeek)} arrow>
						<span className="badge badge-light-primary">
							<i className="fa-solid fa-calendar-week text-primary"></i>
						</span>
					</Tooltip>
				)}
				{recurring?.recurringType === EventRecurringType.Monthly && (
					<Tooltip title={getDayOfMonthDescription(recurring.daysOfMonth)} arrow>
						<span className="badge badge-light-primary">
							<i className="fa-solid fa-calendar-day text-primary"></i>
						</span>
					</Tooltip>
				)}
				<Tooltip title={timeIcon.description} arrow>
					<span className="badge badge-light-primary">{timeIcon.icon}</span>
				</Tooltip>
				<Tooltip title={weekendIcon.description} arrow>
					<span className="badge badge-light-primary">{weekendIcon.icon}</span>
				</Tooltip>
				<Tooltip title={holidayIcon.description} arrow>
					<span className="badge badge-light-primary">{holidayIcon.icon}</span>
				</Tooltip>
				{recurring?.separationTime && (
					<Tooltip
						title={
							moreOptionsIcon.descriptions.map(option =>
								<div>
									<span>{option}</span><br />
								</div>
							)
						}
						arrow
					>
						<span className="badge badge-light-primary">{moreOptionsIcon.icon}</span>
					</Tooltip>
				)
				}
			</div >
		);
	};

	const renderMachineName = (row: StepLogModel) => {
		return (
			<div className="d-flex flex-column">
				<span className="text-dark fw-bolder fs-6">
					{renderMachineVersionIcon(row)} &nbsp;
					{renderMachineConnectionIcon(row)}
					&nbsp;{row.machineName}
				</span>
				<span className="text-muted fw-bold text-muted fs-7">
					{row.processName}
				</span>
			</div>
		)
	}

	const renderMachineVersionIcon = (row: StepLogModel) => {
		const versionName = typeof row.versionName == 'undefined' ? "0.0.0.0" : row.versionName;
		const inUseMessage = t('stepLog.table.tooltips.agentVersion.inUse', { "versionName": versionName });
		const message = row.isVersionUpdateRequired ? `${t('stepLog.table.tooltips.agentVersion.notUpdated')} \n${inUseMessage}` : inUseMessage;
		return (
			<Tooltip title={message} arrow>
				{row.isVersionUpdateRequired ? (<i className="fa-solid fa-refresh text-danger" />) : (<i className="fa-solid fa-refresh text-danger d-none" />)}
			</Tooltip>
		);
	}

	const renderMachineConnectionIcon = (row: StepLogModel) => {
		if (!row.machineIsUp) {
			return (
				<Tooltip title={t('stepLog.table.tooltips.agentConnectionState.disconnected')} arrow>
					<i className="fa-solid fa-link-slash text-danger" />
				</Tooltip>
			);
		}


		if (row.operationSystemSessionState && row.operationSystemSessionState.valueOf() === OperationSystemSessionState.Unlock.valueOf()) {
			return (
				<Tooltip title={t('stepLog.table.tooltips.agentConnectionState.connected')} arrow>
					<i className="fa-solid fa-link text-success" />
				</Tooltip>
			);
		} else {
			return (
				<Tooltip title={t('stepLog.table.tooltips.agentConnectionState.blocked')} arrow>
					<i className="fa-solid fa-link text-warning" />
				</Tooltip>
			);
		}
	}

	const columns = (type: "scheduled" | "nonScheduled"): TableColumn<StepLogModel>[] => [
		{
			name: `${type === "scheduled" ? t("stepLog.table.tableHead.scheduledStepName") : t("stepLog.table.tableHead.nonScheduledStepName")}`,
			minWidth: '200px',
			cell: (row) =>
				<div className="d-flex justify-content-start flex-column">
					<span className="text-dark fw-bolder fs-6">
						{row.name}
					</span>
					{row.isScheduled ? (
						<>
							<span className="text-muted fw-bold text-muted d-block fs-8">
								{t("stepLog.table.nextExecution")}:
								<span className="fw-bolder">
									{
										row.next
											? ` ${formatLocalizedDateTime(row.next, "LLLL")} (${formatRelativeTime(row.next)})`
											: ` ${t("stepLog.table.noScheduledRun")}`
									}
								</span>
							</span>
							<span className="text-muted fw-bold text-muted d-block fs-8">
								{t("stepLog.table.lastExecution")}:
								<span className="fw-bolder">
									{
										row.last
											? ` ${formatLocalizedDateTime(row.last, "LLLL")} (${formatRelativeTime(row.last)})`
											: ` ${t("stepLog.table.noExecutionCompleted")}`
									}
								</span>
							</span>
						</>
					) : (
						<span className="text-muted fw-bold text-muted d-block fs-8">
							{t("stepLog.table.lastExecution")}:
							<span className="fw-bolder">
								{
									row.last
										? ` ${formatLocalizedDateTime(row.last, "LLLL")} (${formatRelativeTime(row.last)})`
										: ` ${t("stepLog.table.noExecutionCompleted")}`
								}
							</span>
						</span>
					)}
					{(row.realTimeLogViewModel) &&
						<div>
							{buildBadges(row.realTimeLogViewModel.custom)}
						</div>
					}
				</div>
		},
		{
			name: `${t("stepLog.table.tableHead.recurring")}`,
			cell: (row: StepLogModel) => row.isScheduled
				? renderRecurringIcons(row, row.eventRecurring)
				: <span className="text-muted fw-bold text-muted fs-7">{t("stepLog.table.recurring.noSchedule")}</span>,
			center: true,
			width: '250px'
		},
		{
			name: `${t("stepLog.table.tableHead.machineName")}`,
			cell: (row: StepLogModel) =>
				row.machineName && row.isScheduled
					? renderMachineName(row)
					: (<span className="text-muted fw-bold text-muted fs-7">{t("stepLog.table.recurring.noSchedule")}</span>)
			,
			width: "180px"
		},
		{
			name: `${t("stepLog.table.tableHead.progress")}`,
			cell: (row) => (
				<div className="d-flex flex-column w-100 me-2">
					<div className="d-flex justify-content-between mb-2">
						<span className="text-muted me-2 fs-7 fw-bold"><i className={`${getTimeLineBadge(row.status ?? AgentLogStatus.NotRunning)} me-2`} />{verifyStepStatus(row.status)}</span>
						{row.status === AgentLogStatus.Running && <span className="text-muted me-2 fs-7 fw-bold">{`${row.percentage}%`}</span>}
					</div>
					{row.status === AgentLogStatus.Running && (
						<div className="progress h-6px w-100">
							<div
								className={`progress-bar bg-${getStatusColor(row.status)}`}
								role="progressbar"
								style={{ width: `${row.percentage}%` }}
							/>
						</div>
					)}
				</div>
			),
			width: "300px"
		},
		{
			name: `${t("stepLog.table.tableHead.actions")}`,
			cell: (row) => renderActionButtons(row),
			width: "150px",
			center: true,
			omit: !tokenHelper.HasAccess([RolesDictionary.Process.Dashboard_Runner])
		}
	];

	const renderActionButtons = (row: StepLogModel) => {
		const { machineIsUp, status } = row;

		const isStartButtonDisabled = row.isScheduled
			? !machineIsUp || status === AgentLogStatus.Running || status === AgentLogStatus.Triggered
			: isLoading || status === AgentLogStatus.Running || status === AgentLogStatus.Triggered;

		const isStopButtonDisabled = !row.machineIsUp || isLoading || row.status !== AgentLogStatus.Running || row.type == StepType.HttpCall;
		const isScreenshotButtonDisabled = !row.machineIsUp || !row.operationSystemSessionState || row.operationSystemSessionState.valueOf() === OperationSystemSessionState.Lock.valueOf() || row.type == StepType.HttpCall;

		const startButton = () => {
			return <>
				<Tooltip title={t('generalMessages.start')} arrow>
					<span>
						<button
							className="btn btn-icon btn-bg-light btn-active-color-success btn-sm me-1"
							type="button"
							onClick={() => handleStartStep(row)}
							disabled={isStartButtonDisabled}
						>
							<i className="fa-solid fa-play" />
						</button>
					</span>
				</Tooltip>
			</>
		}

		const stopButton = () => {
			return <>
				<Tooltip title={t('generalMessages.stop')} arrow>
					<span>
						<button
							className="btn btn-icon btn-bg-light btn-active-color-danger btn-sm me-1"
							type="button"
							onClick={() => handleStopButtonClicked({ stepId: row.stepId, eventId: row.eventId })}
							disabled={isStopButtonDisabled}
						>
							<i className="fa-solid fa-stop" />
						</button>
					</span>
				</Tooltip>
			</>
		}

		const refreshButton = () => {
			return <>
				<Tooltip title={t('generalMessages.refresh')} arrow>
					<span>
						<button
							className="btn btn-icon btn-bg-light btn-active-color-primary btn-sm mt-1"
							type="button"
							onClick={() => refreshStep({ stepId: row.stepId, eventId: row.eventId })}
							disabled={!row.machineIsUp || isLoading || row.status === AgentLogStatus.Running || row.status === AgentLogStatus.Triggered}
						>
							<i className="fa-solid fa-arrows-rotate" />
						</button>
					</span>
				</Tooltip>
			</>
		}

		const screenshotButton = () => {
			const hash = status === AgentLogStatus.Running ? row.realTimeLogViewModel?.hashRun : undefined

			return <>
				<FeatureFlag feature={FeatureFlagEnum.Screenshot}>
					<Tooltip title={t('generalMessages.screenshot')} arrow>
						<span>
							<button
								className="btn btn-icon btn-bg-light btn-active-color-primary btn-sm mt-1"
								type="button"
								onClick={() => { setScreenshotModalData({ machineName: row.machineName!, stepId: row.stepId, hashRun: hash }) }}
								disabled={isScreenshotButtonDisabled}
							>
								<i className="fa-solid fa-camera" />
							</button>
						</span>
					</Tooltip>
				</FeatureFlag>
			</>
		}

		return (
			<>
				<div className="container">
					<div className="row">
						<div className="col">
							<div className="d-flex justify-content-center flex-shrink-0">
								{startButton()}
								{stopButton()}
								{refreshButton()}
							</div>
						</div>
					</div>
					<div className="row">
						<div className="col">
							<div className="d-flex justify-content-center flex-shrink-0">
								{screenshotButton()}
							</div>
						</div>
					</div>
				</div>
			</>
		)
	}

	const handleAccordionOnChange = (key: string) => {
		if (expanded(key)) {
			setExpandedDepartments(expandedDepartments.filter(x => x !== key));
		}
		else {
			setExpandedDepartments([...expandedDepartments, key]);
		}
	}

	const expanded = (key: string) => {
		return expandedDepartments.some(x => x === key);
	}

	useEffect(() => {
		if (filtered) {
			setExpandedDepartments(filteredGroupedRobots.map(x => x.key));
		}
		else {
			setExpandedDepartments([]);
		}
	}, [filtered, filteredGroupedRobots])

	const renderRobotsAccordionByDepartment = () => {

		return (
			<div className="row gy-3 gy-xl-4">
				{filteredGroupedRobots.map((robot, index) => (
					<div className="col-12" key={index}>
						<Accordion className="shadow-none p-2" expanded={expanded(robot.key)} onChange={() => handleAccordionOnChange(robot.key)}>
							<AccordionSummary
								expandIcon={<ExpandMoreIcon />}
								id={`${robot.key}`}
							>
								<h3 className="d-flex align-items-center align-middle">
									<span className="fw-bolder text-dark me-3">{robot.key}</span>
								</h3>
							</AccordionSummary>
							<AccordionDetails>
								<div style={{ width: "100%", borderTop: "5px dotted #eee" }}>
									{robot.events.filter((event: StepLogModel) => event.isScheduled).length > 0 && (
										<div className={`overflow-auto ${robot.events.filter(event => !event.isScheduled).length > 0 ? "border-bottom" : ""}`}>
											<div className="dataTables_wrapper dt-bootstrap4 no-footer">
												<DataTable
													columns={columns("scheduled")}
													data={robot.events.filter((event: StepLogModel) => event.isScheduled)}
													customStyles={itLabTableStyle}
												/>
											</div>
										</div>
									)}
									{robot.events.filter((event: StepLogModel) => !event.isScheduled).length > 0 && (
										<>
											<div className="overflow-auto">
												<div className="dataTables_wrapper dt-bootstrap4 no-footer">
													<DataTable
														columns={columns("nonScheduled")}
														data={robot.events.filter((event: StepLogModel) => !event.isScheduled)}
														customStyles={itLabTableStyle}
													/>
												</div>
											</div>
										</>
									)}
								</div>

							</AccordionDetails>
						</Accordion>
					</div>
				))}
			</div>
		);
	};

	useEffect(() => {
		if (screenshotModalData) {
			setShowScreenshotModal(true);
		}
	}, [screenshotModalData])

	return (
		<>
			{stepLogIsLoading && filteredGroupedRobots.length === 0 && (
				<div className="text-center mt-6 mb-6 mb-xl-8 mt-xl-0">
					<LoadingSpinner size="lg" loading={stepLogIsLoading} />
				</div>
			)}

			{!stepLogIsLoading && filteredGroupedRobots.length === 0 && (
				<>
					<div className="card text-center p-0">
						<div className="card-body">
							<h3 className="card-title align-items-start flex-column">
								<span className="fw-bolder mb-2 text-dark">{t("stepLog.title")}</span>
								<br />
								<span className="text-muted fw-bold fs-7">{t("stepLog.noDataMessage")}</span>
							</h3>
						</div>
					</div>
				</>
			)}

			{renderRobotsAccordionByDepartment()}

			<ExecutionLogAvailableMachinesModal
				open={openModal}
				onClose={handleCloseModal}
				startUnscheduledStep={startUnscheduledStep}
				stepExecutionData={stepExecutionData}
				hubConnection={hubConnection}
			/>

			{screenshotModalData &&
				<ScreenshotModal data={screenshotModalData} show={showScreenshotModal} onClose={() => setShowScreenshotModal(false)} />
			}
		</>
	);
};
