import { EventRecurring } from "./recurring.model";

export enum OperationSystemSessionState {
	Lock,
	Unlock
}

export interface MachineAndStepStatisticsModel {
	totalQuantity: number;
	active: number;
	inactive: number;
	percentActive: number;
	percentInactive: number;
}

export interface StepLogModel {
	eventId?: number;
	stepId: number;
	name: string;
	processName: string;
	processId: number;
	next?: string;
	last?: string;
	machineId?: number;
	machineName?: string;
	machineIsUp?: boolean;
	percentage?: number;
	department: string;
	realTimeLogViewModel?: RealTimeLogViewModel;
	status?: number;
	eventRecurring?: EventRecurring;
	eventStartTime?: string;
	eventEndTime?: string;
	isScheduled: boolean;
	versionName: string;
	isVersionUpdateRequired: boolean;
	operationSystemSessionState?: OperationSystemSessionState;
	type: StepType;
}

export enum StepType {
	Executable = 0,
	HttpCall = 1
}

export interface StepLogActionByEventPostModel {
	eventId?: number;
	stepId: number;
}

export interface StepLogActionByStepPostModel {
	stepId: number;
	machineIds: number[];
}

export interface StepLogActionScreenShotPostModel {
	machineName: string;
	stepId: number;
	hashRun?: string;
}

export interface NextAndLastRunModel {
	runDate: string;
	stepName: string;
	status: number;
	statusText: string;
}

export interface NextAndLastRunRequestModel {
	timeRange?: number;
}

export interface ConnectionsViewModel {
	totalQuantity: number;
	active: number;
	inactive: number;
	percentActive: number;
	percentInactive: number;
	secondCounter?: number;
}

export interface RealTimeLogViewModel {
	custom: string | null;
	update: string;
	progress: number;
	hashRun: string;
}

export interface UpdateStatusAction {
	status: AgentLogStatus;
	eventId: number;
	realTimeLogViewModel: RealTimeLogViewModel
}

export interface ScreenshotModalData {
	machineName: string;
	stepId: number;
	hashRun?: string;
}

export enum AgentLogStatus {
	NotRunning,
	Running,
	Success,
	Stopped,
	Error,
	Suspect,
	Conflict,
	Triggered
}

export const AgentLogStatusLabel = {
	[AgentLogStatus.NotRunning]: "waiting",
	[AgentLogStatus.Running]: "running",
	[AgentLogStatus.Success]: "success",
	[AgentLogStatus.Stopped]: "stopped",
	[AgentLogStatus.Error]: "error",
	[AgentLogStatus.Suspect]: "suspect",
	[AgentLogStatus.Conflict]: "conflict",
	[AgentLogStatus.Triggered]: "triggered"
}
