export const APP_ROUTES = {
  blank: '',
  notfoundpage: '404',
  unauthorizedpage: 'unauthorized',
  login: 'login',
  recoverypassword: 'recoverypassword',
  loading: 'loading',
  auth: 'auth',
  dashboard: 'dashboard',
  security: 'security',
  settings: 'settings',
  samples: 'samples',
  processes: 'processes',
  masterdata: 'masterdata',
  infrastructure: 'infrastructure',
  userprofile: 'userprofile',
  healthcheck: 'healthcheck',
  generalsettings: 'generalsettings',
  basic: {
    list: 'basic',
    add: 'basic/add',
    edit: 'basic/:id',
  },
  complexdatabase: {
    list: 'complexdatabase',
    add: 'complexdatabase/add',
    edit: 'complexdatabase/:id',
  },
  complexfrontend: {
    list: 'complexfrontend',
    add: 'complexfrontend/add',
    edit: 'complexfrontend/:id',
  },
  detailscreen: {
    list: 'detailscreen',
    edit: 'detailscreen/:id',
  },
  tenantmanagement: {
    list: 'tenantmanagement',
    add: 'tenantmanagement/add',
    edit: 'tenantmanagement/:id',
  },
  usermanagement: {
    list: 'usermanagement',
    add: 'usermanagement/add',
    edit: 'usermanagement/:id',
  },
  groupmanagement: {
    list: 'groupmanagement',
    add: 'groupmanagement/add',
    edit: 'groupmanagement/:id',
  },
  countries: {
    list: 'countries',
    add: 'countries/add',
    edit: 'countries/:id',
  },
  errorreasons: {
    list: 'errorreasons',
    add: 'errorreasons/add',
    edit: 'errorreasons/:id',
  },
  departaments: {
    list: 'departaments',
    add: 'departaments/add',
    edit: 'departaments/:id',
  },
  sites: {
    list: 'sites',
    add: 'sites/add',
    edit: 'sites/:id',
  },
  robotType: {
    list: 'robotType',
    add: 'robotType/add',
    edit: 'robotType/:id',
  },
  operationsystem: {
    list: 'operationsystem',
    add: 'operationsystem/add',
    edit: 'operationsystem/:id',
  },
  process: {
    list: 'process',
    add: 'process/add',
    edit: 'process/:id',
  },
  processSteps: {
    list: 'processSteps',
    add: 'processSteps/add',
    edit: 'processSteps/:id',
  },
  scheduled: {
    list: 'scheduled',
    add: 'scheduled/add',
    edit: 'scheduled/:id',
  },
  departamentsLink: {
    list: 'departamentsLink',
    add: 'departamentsLink/add',
    edit: 'departamentsLink/:id',
  },
  technologies: {
    list: 'technologies',
    add: 'technologies/add',
    edit: 'technologies/:id',
  },
  machineType: {
    list: 'machineType',
    add: 'machineType/add',
    edit: 'machineType/:id',
  },
  machine: {
    list: 'machine',
    add: 'machine/add',
    edit: 'machine/:id',
  },
  machineActivity: {
    list: 'machineActivity',
  },
  schedule: 'schedule',
  apiClients: 'apiclients',
  robotExecutions: {
    list: 'robotExecutions',
  },
  agentVersions: 'versions',
  notifications: {
    base: 'notifications',
    center: 'center',
    view: 'center/:id',
  },
};
